.pretestscreencontainer{
   margin-top: 13vh;
}

.pretestscreenheading{
    margin-top: 2rem;
    background-color: aquamarine;
    padding: 10px;
}

.pretestscreenduration{
    padding: 0.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgb(3,56,104);
}

.pretestscreencontainer ol{
   text-align: start;
   margin-top: 2rem;
}
.pretestscreencontainer ol li{
    margin-left: 1rem;
    font-weight: 500;
}

.preteststartbtn{
    position: absolute;
    bottom: 5%;
    right: 2.5%;
    border: 1px solid rgb(3,56,104);
    background-color: rgb(3,56,104);
    color: #fff;
    cursor: pointer;
    padding: 0.25rem 1.25rem;
    border-radius: 25px;
}

.preteststartbtndisabled{
    position: absolute;
    bottom: 5%;
    right: 2.5%;
    border: 1px solid rgb(3,56,104);
    background-color: rgb(3,56,104);
    color: #fff;
    padding: 0.25rem 1.25rem;
    border-radius: 25px;
    cursor: not-allowed;
    opacity: 0.25;
}