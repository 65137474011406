.adminstudentprofilecontainer{
    margin-top: 15vh;
    width: 60%;
    margin-left: 1.5rem;
    background-color: aquamarine;
    padding: 1rem;
    border-radius: 8px;
}
.adminstudentprofileminicontainer1{
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.adminstudentprofileminicontainer1 span{
    background-color: rgb(3,56,104);
    color: #fff;
    padding: 0.2rem 1rem;
    margin-right: 0.5rem;
    border-radius: 25px;
}

.adminstudentprofileminicontainer2{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.addressinputstudentportal input{
    width: 98%;
    outline: none;
    border: none;
    padding: 0.5rem;   
}
.addressinputstudentportal input:focus{
    outline: none;
}
.adminstudentprofileminicontainer2 input {
    padding:0.25rem;
    width: 25vw;
    margin-top: 2px;
    border: 1px solid rgba(0,0,0,0.1);
}
.adminstudentprofileminicontainer2 input:focus{
    outline: none;
}


.marginrightinput{
    padding-right: 3rem;
}

.gendershortinput input{
    width: 50% !important;
}

.inputcontentstyle{
    /* background-color: rgb(3,56,104); */
}

@media(max-width:1000px){
    .adminstudentprofileminicontainer2 input{
        width: 40vw;
    }
    
}