.walletbalancestrip{
    margin-top: 15vh;
    background-color: aquamarine;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.walletbalancestrip span{
    margin-right: 1rem;
    padding: 0.25rem 1rem;
    border-radius: 20px;
    background-color: rgb(3,56,104);
    color: #fff;
    font-weight: bold;
    cursor: pointer;
}

.Modalheadercontainer div{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(3,56,104);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: #fff;
}

.modalmaincontainer{
    border-radius: 40px;
}

.modalfootercontainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.modalfootercontainer button{
    border: none;
    background-color: rgb(3,56,104);
    color: #fff;
    padding: 0.25rem 1rem;
    border-radius: 25px;
}