.profilecontainer{
    margin-top: 12vh;
    font-weight: 500;
    color: rgb(3,56,104);
}
.sprofilecontainer{
    width: 60%;
    margin-left: 1.5rem;
    background-color: aquamarine;
    padding: 1rem;
    border-radius: 8px;
}

.studentprofileminicontainer1{
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.studentprofileminicontainer1 span{
    background-color: rgb(3,56,104);
    color: #fff;
    padding: 0.2rem 1rem;
    margin-right: 0.5rem;
    border-radius: 25px;
}

.studentprofileminicontainer2{
    display: flex;
    text-align: start;
}
.xyz1{
    width: 100%;
}
.xyz2{
    width: 100%;
}
.divinputcontainer{
    display: flex;
    align-items: center;
    width: 80%;
    border: 1px solid silver;
    background-color: #fff;
    font-size: small;
}

.divinputcontainer p{
    margin-bottom: 0;
    padding: 0.4rem 0.2rem;
}

.divinputcontainermini{
    display: flex;
    align-items: center;
    width: 40%;
    border: 1px solid silver;
    background-color: #fff;
    font-size: small;
}
.divinputcontainermini p{
    margin-bottom: 0;
    padding: 0.4rem 0.2rem;
}

.studentprofileminicontainer3{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
    margin-top: 1rem;
}

.studentprofileminicontainer4{
    display: flex;
    text-align: start;
}

@media(max-width:768px){
    .profilecontainer{
        margin-top: 13vh;
    }
    .studentprofileminicontainer2{
        flex-direction: column;
    }
    .studentprofileminicontainer4{
        flex-direction: column;
    }
}