.NavbarCustom{
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(3,56,104);
    color: #fff;
    padding:0.5rem  1rem ;
}

.NavbarCustom ul{
    display: flex;
    align-items: center;
    margin-top: 0.25rem;
}

.NavbarCustom ul li{
    text-decoration: none;
    list-style: none;
    margin-right: 1rem;
    background-color: #fff;
    color: rgb(3,56,104);
    font-weight: bold;
    padding:0.25rem 0.5rem;
    border-radius:20px ;
    cursor: pointer;
    
}
@media(max-width:640px){
    .NavbarCustom ul li{
        margin-right: 0.25rem;
    }
}

/* .NavbarCustom ul li:hover{
    background-color: rgb(3,56,104);
    color: #fff;
} */

