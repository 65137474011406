.TeacherMainContainer{
    margin-top: 14vh;
}
.teacheraddpapercontainer{
    text-align: end;
    margin-right: 1rem;

}
.teacheraddpapercontainer button{
    border: none;
    border-radius: 25px;
    background-color: rgb(3,56,104);
    padding: 0.25rem 1rem;
    color: #fff;
}

.teacherpapercontainer{
    width: 100%;
    background-color: aquamarine;
    margin-top: 1rem;
    padding: 0.5rem 0;
}