.submitscreenmaincontainer{
    margin-top: 14vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 86vh;
}

.submitscreenmaincontainer button{
    width: 100px;
    margin: 1rem;
    border: none;
    border-radius: 25px;    
    background-color: rgb(3,56,104);
    padding: 0.25rem 1rem;
    color: aliceblue;
}

.submitscreenmaincontainer i{
    margin-bottom: 2rem;
    color: green;
}