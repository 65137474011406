.instructionpagecontentcontainer{
    margin-top: 13vh;
}
.instructionpageheading{
    margin-top: 2rem;
    background-color: aquamarine;
    padding: 10px;
}

.instructionpagenextbtn{
    border: none;
    padding: 0.25rem 1.5rem;
    background-color: rgb(3,56,104);
    color: #fff;
    border-radius: 25px;
}