.studentportalmaincontainer{
    margin-top: 12vh;
    height: auto;
}

.examcontainer{
    background-color: rgb(147, 228, 228);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.examcontainer ul{
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
}
.examcontainer ul li{
    align-items: center;
    margin-right: 3rem;
    list-style: none;
    background-color: rgb(3,56,104);
    color: #fff;
    padding: 0.25rem 1rem;
    border-radius: 20px;
    cursor: pointer;
}
@media(max-width:640px){
    .examcontainer ul li{

        margin-right: 0.2rem;
    }
}
.examcontainer ul li:hover{
    background-color: #fff;
    color: rgb(3,56,104);
    font-weight: bold;
}

.blankcontainer{
    margin-top: 0.5rem;
    height: 25vh;
    border: 1px solid rgb(223, 221, 221);
    background-color: rgb(226, 218, 218);
}
.myexamstrip{
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    text-align: start;
     height: 10vh;
}

.myexamstrip h4{
    margin-left: 2rem;
}

.myexamlist thead{
     background-color: aquamarine;
}
.myexamtablelastchild{
    padding: 0.25rem 1rem;
    border-radius: 20px;
    background-color: rgb(3,56,104);
    color: #fff;
    cursor: pointer;
}

.modalcontainerbtn{
    margin-left: 1rem;
    background-color: #fff !important;
    color: rgb(3,56,104) !important;
    border: 1px solid rgb(3,56,104) !important;
}

.styleh4tag:hover{
    text-decoration: underline;
    transition: all 0.5s ease;
    cursor: pointer;
}