.adminmainportalcontainer{
    margin-top: 12vh;
}

.selectcontainer{
    height: 5vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem;
    margin: 0 2rem 0 2rem;
}

.selectionbtn{
    margin-left: 2rem;
    border: 1px solid rgb(3,56,104);
    padding: 0.25rem 1rem;
    cursor: pointer;
    color:  rgb(3,56,104);
    font-weight: bold;
    border-radius: 20px;
}

.selectionbtnonclick{
    margin-left: 2rem;
    border: 1px solid silver;
    padding: 0.25rem 1rem;
    cursor: pointer;
    background-color:  rgb(3,56,104);
    color: #fff;
    font-weight: bold;
    border-radius: 20px;
}

.infoadmin{
    display: flex;
    justify-content: flex-start;
    background-color: aquamarine;
}
.infoadmin h2{
    padding: 1rem;
    margin-left: 5rem;
}

.studentdetailsbtn{
    padding: 0.25rem 1rem;
    background-color: rgb(3,56,104);
    color: #fff;
    border-radius:20px ;
    cursor: pointer;
}

.tbodycontainer{
    width: 100%;
    max-height: 50vh;
}

.searchcontainer{
    display: flex;
    justify-content: flex-start;
    padding: 1rem;
    margin-left: 5rem;
    align-items: center;
}
.searchcontainer input{
    border: 1px solid rgba(0,0,0,0.1);
}
.searchcontainer input:focus{
    outline: none;
}
.resetdatebtn{
    padding: 0.1rem 1rem ;
    border-radius: 25px;
    margin-left: 1rem;
    background-color: rgb(3,56,104);
    color: #fff;
}