.examformHandler{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.examformHandler label{
    font-weight: bold;
    margin: 1rem;
}
.examformHandler button{
    border: none;
    background-color: rgb(3,56,104);
    color: white;
    font-weight: bold;
    border-radius: 20px;
    padding: 0.25rem 1rem;
    margin-top: 1rem;
}

.examformHandler button:hover{
    color: rgb(3,56,104);
    background-color: white;
    border: 1px solid rgb(3,56,104);
}
.optionsinput{
    border-top:none ;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid silver;
}
.optionsinput:focus{
    outline: none;
}
.addquestioninput{
    border-top:none ;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid silver;
    width: 40vw;
}
.addquestioninput:focus{
    outline: none;
}