.HomeContainer{
    width:100vw;
    height: 100vh;
    position: relative;
}
.HomeContainer::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    /* background-image: url('https://images.unsplash.com/photo-1501349800519-48093d60bde0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y29sb3JlZCUyMHBlbmNpbHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60'); */
    background-image: url('../Assets/images/cpb.jpg');
    width: 100%;
    height: 100%; 
    background-size: cover;
    background-position: center left;
}

.homecontainerbtn{
    position: absolute;
    top: 5%;
    right: 8%;
    border: 1px solid rgba(3,56,104,0.5);
    padding: 0.4rem 2rem;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border-radius: 25px;
    cursor: pointer;    
    color: rgb(3,56,104);
}

.homecontainerbtn:hover{
    background-color: rgb(3,56,104);
    color: #fff;
}

.homecontainerbtn2{
    position: absolute;
    top: 5%;
    right: 18%;
    border: 1px solid rgba(3,56,104,0.5);
    padding: 0.4rem 2rem;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border-radius: 25px;
    cursor: pointer;    
    color: rgb(3,56,104);
    margin-right: 2rem;
}

.homecontainerbtn2:hover{
    background-color: rgb(3,56,104);
    color: #fff;
}

@media(max-width:1050px){
    .homecontainerbtn2{
        right: 20%;
    }
}
@media(max-width:800px){
    .homecontainerbtn2{
        right: 22%;
    }
}
@media(max-width:675px){
    .homecontainerbtn2{
        right: 25%;
    }
}
@media(max-width:550px){
    .homecontainerbtn2{
        right: 29%;
    }
}
@media(max-width:500px){
    .homecontainerbtn2{
        right: 33%;
    }
}
.logincontainer{
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
    position: absolute;
    right: 12%;
    top: 25%;
    padding:3rem;
    padding-top: 0;
    border-radius: 10px;
    background-color: #A9E8FC;
    color: rgb(3,56,104);
    box-shadow: 5px 5px 10px rgba(3,56,104,0.25);
}
.logincontainer h3{
    font-weight: 500;
    margin-bottom: 3rem;
}

.logincontainer input{
    margin-bottom: 1rem;
}
.logincontentcontainer{
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.logincontentcontainer h3{
    margin-top: 1rem;
}

.logincontentcontainer h3 i{
    position: absolute;
    left: 10%;
}

.inputcontentcontainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* align-items: center; */
    /* justify-content: ; */
}
.inputcontentcontainer label{
    margin-bottom: 5px;
}
.inputcontentcontainer input {
    border: none;
    padding: 0.5rem;
    width: 300px;
    margin-right: 1rem;
}

.inputcontentcontainer input:focus{
    outline: none;
}

.homecontainerlsbtn{
    border: 1px solid rgba(3,56,104,0.5);
    padding: 0.4rem 2rem;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border-radius: 25px;
    cursor: pointer;    
    color: #fff;
    background-color: rgb(3,56,104);
}

.homecontainerlsbtn:hover{
    background-color: #fff;
    color: rgb(3,56,104);
    font-weight: bold;
}


@media(max-width:550px){
    .logincontainer{
      position: absolute;
      right: 3em;   
    }
    .inputcontentcontainer input{
        width: 100%;
    }
}

@media(max-width:370px){
    .logincontainer{
      position: absolute;
      right: 0em;   
    }
    .inputcontentcontainer input{
        width: 100%;
    }
}


.inputcontentcontainerRegistration{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    /* align-items: center; */
    /* justify-content: ; */
}
.inputcontentcontainerRegistration label{
    margin-bottom: 5px;
}
.inputcontentcontainerRegistration input {
    border: none;
    padding: 0.5rem;
    width: 300px;
    margin-right: 1rem;
}

.inputcontentcontainerRegistration input:focus{
    outline: none;
}

.inputcontentcontainerRegistrationleftside{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.inputcontentcontainerRegistrationrightside{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
/* .inputcontentcontainerRegistrationrightside button{
    position: absolute;
    right: 10%;
    bottom: 10%;
} */

#gender{
    width: 120px;
}

.addressinput{
    width: 100%;
}

.addressinput input{
    outline: none;
    border: none;
    padding: 0.5rem;   
}
.addressinput input:focus{
   outline: none;
}
.postalcode{
    width: 100%;
    display: flex;
}

@media(max-width:880px){
    .postalcode{
        flex-direction: column;
    }
}

.postalcode input{
    outline: none;
    border: none;
    padding: 0.5rem;   
}
.postalcode input:focus{
    outline: none;
 }



 .restitemscontent{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    /* align-items: center; */
    /* justify-content: ; */
}
.restitemscontent label{
    margin-bottom: 5px;
}
.restitemscontent input {
    border: none;
    padding: 0.5rem;
    width: 300px;
    margin-right: 1rem;
}

.restitemscontent input:focus{
    outline: none;
}

.registrationcontainer{
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
    position: absolute;
    right: 8%;
    top: 5%;
    padding:3rem;
    padding-top: 0;
    background-color: #A9E8FC;
    color: rgb(3,56,104);
    box-shadow: 5px 5px 10px rgba(3,56,104,0.1);
}


@media(max-width:880px){
    .registrationcontainer{
        right: 2%;
    }
    .inputcontentcontainerRegistration{
        flex-direction: column;
    }
    .restitemscontent{
        flex-direction: column;
    }
    .HomeContainer{
        overflow:scroll !important;
    }
    .inputcontentcontainerRegistration input {
    border: none;
    padding: 0rem;
    width: 300px;
    margin-right: 1rem;
}
.addressinput input{
    padding: 0rem;
}
.postalcode input{
    padding: 0;
}
.restitemscontent input{
    padding: 0;
}
}

.forgotpassword{
    position: absolute;
    right: 5%;
    bottom: 5%;
    margin-top: 2rem;
    color: rgb(3,56,104);
    text-decoration: none;
    font-weight: bold;
}

.preparingforcontainer{
    max-width: 300px;
    background-color: #fff;
    padding: 0;

}
.iconcolorchange{
    color: rgb(3,56,104);
}