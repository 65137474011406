.textpagecontentcontainer{
    margin-top: 13vh;
    display: flex;
    flex-direction: column;
}


.textcontainerexamandtime{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: aquamarine;
}

.textcontaineroptionstab{
    display: flex;
    justify-content: space-between;
    padding: 1rem;

}
.testMainContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    /* height: 60vh; */
    height: auto;
    margin-top: 1rem;

}

@media(max-width:1000px){
    .testMainContainer{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        /* border: 1px solid red; */
    }
    .testMainContainer1{
        width: 100vw;
    }
    .testMaincontainer2{
        width: 90vw;
        /* padding: 0; */
        height: auto;
        margin-top: 2rem;
        flex-direction: column;
    }
}

.testMaincontainer1{
    width: 70%;
    text-align: start;
    padding: 1rem;
    position: relative;
}

.testMainContainer1 form input{
    border-radius: 100%;
}
.testMaincontainer1 li{
    list-style: none;
}

.testMaincontainer2{
    width: 25%;
    background-color: aquamarine;
    border-radius: 15px ;
    text-align: center;
    /* display: flex;
    flex-direction: column;
    flex-wrap: wrap; */
}
/* .testMaincontainer2 button{
    position: absolute;
    bottom: 5%;
    right: 35%;
} */

.currentquestionnumbercontainer{
    padding: 0.25rem 0.5rem;
    margin:1rem 0.5rem;
    background-color: rgb(3,56,104);
    color: #fff;
    border-radius: 10px;
}

.altcurrentquestionnumbercontainer{
    padding: 0.25rem 0.5rem;
    margin:1rem 0.5rem;
    background-color: #fff;
    color: rgb(3,56,104);
    border-radius: 10px;
}

.saveandnextbtn{
    position: absolute;
    bottom: 2%;
    right: 6%;
    border-radius: 25px;
    padding: 0.25rem 1.25rem;
    background-color: rgb(3,56,104);
    color: #fff;
    border: none;
}

.disablebsaveandnextbtn{
    position: absolute;
    bottom: 2%;
    right: 8%;
    border-radius: 25px;
    padding: 0.25rem 1.25rem;
    background-color: rgb(3,56,104);
    color: #fff;
    border: none;
    cursor: not-allowed;
}
.disablebskipandnextbtn{
    position: absolute;
    bottom: 2%;
    right: 2%;
    cursor: not-allowed;
    margin-left: 2rem;
    margin-bottom: 2px;
    color:  rgb(3,56,104);
}
.optionsinputchoice
{
    margin-top: 1rem;
}

.languagebtn
{
    border: 1px solid rgb(3,56,104);
    background-color: #fff;
    color: rgb(3,56,104);
    padding: 0.25rem 1rem;
    border-radius: 25px;
    cursor: pointer;
}
.languagebtnActive{
    border: 1px solid rgb(3,56,104);
    background-color: rgb(3,56,104);
    color: #fff;
    padding: 0.25rem 1rem;
    border-radius: 25px;
    cursor: pointer;
}

.skipandnextbtn{
    position: absolute;
    bottom: 2%;
    right: 1%;
    cursor: pointer;
    margin-left: 12rem;
    margin-bottom: 2px;
    color:  rgb(3,56,104);
    /* border: 1px solid blue; */
}

@media(max-width:1000px){
    .skipandnextbtn{
        right: -8%;
        /* margin-left: 12rem; */
        /* padding-left: 8rem; */
    }
    .disablebskipandnextbtn{
        right: -8%;
    }
}