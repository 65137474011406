.showgivenexammaincontainer{
    margin-top: 13vh;
}

.showexamquestioncontainer{
    display: block;
}

.showexamright{
    background-color: rgb(165, 230, 165);
}

.showexamwrong{
    background-color: rgb(233, 138, 138);
}