.finalscreencontainer{
    margin-top: 13vh;
    
}

.finalscreencardcontentcontainer{
   display: flex;
   justify-content: space-evenly;
   flex-wrap: wrap;

}

.smalllistcard{
    width: 250px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.smalllistcard h4{
    background-color: rgb(3,54,104);
    padding: 0.25rem 1.25rem;
    color: #fff;
}
.smalllistcard p{
    margin-top: 1rem;
    font-weight: 700;
    font-size: 20px;
}

.finalscreenhomebtn{
    border: none;
    background-color: rgb(3,54,104);
    color: #fff;
    padding: 0.25rem 1.25rem;
    border-radius:25px;
}